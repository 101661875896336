import React, { useState, useEffect } from 'react';

const FullscreenMap = ({ location }) => {
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);

  useEffect(() => {
    // Initialize Google Maps
    const initMap = () => {
      const GOOGLE_MAPS_API_KEY = 'AIzaSyBF-RjhZJBnbV3Xh2Iv14Qi54vCAryvNI4';

      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}`;
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        const defaultLocation = { lat: -33.4569, lng: -70.6483 };
        const mapInstance = new window.google.maps.Map(document.getElementById('map'), {
          zoom: 15,
          center: location || defaultLocation,
          styles: [
            // ... estilos del mapa
          ],
        });
        setMap(mapInstance);

        const markerInstance = new window.google.maps.Marker({
          map: mapInstance,
          icon: {
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 10,
            fillColor: "#4F46E5",
            fillOpacity: 1,
            strokeWeight: 2,
            strokeColor: "#ffffff",
          },
        });
        setMarker(markerInstance);
      };
    };

    if (window.google) {
      initMap();
    }
  }, [location]);

  useEffect(() => {
    if (map && marker) {
      map.panTo(location);
      marker.setPosition(location);
    }
  }, [location, map, marker]);

  return (
    <div className="bg-gray-800 dark:bg-gray-700 rounded-lg p-4 shadow-lg dark:shadow-none">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-medium">Live Location</h2>
        {location && (
          <span className="text-sm text-gray-400 dark:text-gray-400">
            Lat: {location.lat.toFixed(4)} | Lng: {location.lng.toFixed(4)}
          </span>
        )}
      </div>
      <div id="map" className="h-[500px] w-full rounded-lg" />
    </div>
  );
};

export default FullscreenMap;