import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import DriverDashboardHeader from './components/DriverDashboardHeader';

import Register from './components/Register';
import MenuBar from './components/MenuBar';
import DriverDashboard from './components/DriverDashboard';
import Profile from './components/Profile';
import Settings from './components/Settings';
import IOSInstallGuide from './components/IOSInstallGuide'; // Add this import
import FullscreenMap from './components/FullscreenMap';
import DriverLogin from './components/DriverLogin';
import DeliveryTracker from './components/DeliveryTracker';


function App() {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const isDarkMode = localStorage.getItem('darkMode') === 'true';
    setDarkMode(isDarkMode);
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, []);

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem('darkMode', newDarkMode);
    if (newDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  };
  const orderData = {
    // Coordenadas del restaurante
    pickup_latitude: 40.4168, // Ejemplo: Madrid
    pickup_longitude: -3.7038,
    
    // Coordenadas del punto de entrega
    delivery_latitude: 40.4154,
    delivery_longitude: -3.7106,
    
    // Estado inicial del pedido
    status: 'pending', // Valores posibles: 'pending', 'pickup', 'in_transit', 'delivered', 'cancelled'
  };

  return (
    <Router>
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900 transition-colors duration-200">
        <DriverDashboardHeader darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
        <main className="container mx-auto pt-16 pb-14 text-gray-900 dark:text-white">
          <Routes>
            <Route path="/" element={<DeliveryTracker 
  orderId="123" 
  initialOrder={orderData} 
  googleMapsApiKey="AIzaSyDa-TFDa5Fjg-TsoTxfIIkLzpQZLY77fJA"
/>} />

   
            <Route path="/login" element={<DriverLogin />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<Profile />} />

            <Route path="/settings" element={<Settings />} />
            <Route path="/active-mode" element={<FullscreenMap />} />
            <Route path="/download" element={<IOSInstallGuide />} /> 
            
           
          </Routes>
        </main>
        <MenuBar />
      </div>
    </Router>
  );
}

export default App;