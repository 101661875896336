import React from 'react';
import { Bell, Power } from 'lucide-react';

const DriverDashboardHeader = ({ isWorking, toggleWorkStatus }) => {
  return (
    <nav className="bg-gray-800 border-b border-gray-700">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <span className="text-xl font-bold">DriverApp</span>
          </div>
          <div className="flex items-center space-x-4">
            <button className="p-2 rounded-full hover:bg-gray-700 relative">
              <Bell className="h-6 w-6" />
              <span className="absolute top-0 right-0 block h-2 w-2 rounded-full bg-red-500"></span>
            </button>
            <div className="flex items-center space-x-3">
              <img
                className="h-8 w-8 rounded-full"
                src="/api/placeholder/32/32"
                alt="Driver profile"
              />
              <span className="text-sm font-medium">John Doe</span>
            </div>
            <button
              onClick={toggleWorkStatus}
              className={`flex items-center gap-2 px-4 py-2 rounded-lg font-medium transition-colors
                ${isWorking ? 
                  'bg-red-600 hover:bg-red-700' : 
                  'bg-green-600 hover:bg-green-700'
                }`}
            >
              <Power className="h-4 w-4" />
              {isWorking ? 'End Shift' : 'Start Shift'}
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default DriverDashboardHeader;